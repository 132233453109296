/* global moment */
import { application } from 'services/core'

function SubscriptionPromotion () {
  const elements = {}

  const initializeSelectors = () => {
    elements.subscriptionPromotionId = document.querySelector('#subscription_promotion_id')?.value
    elements.stores = document.querySelector('#stores')
    elements.serviceType = document.querySelector('#service_types')
    elements.addService = document.querySelector('#add_services_to_subscription')
    elements.associatedServiceIds = document.querySelector('#subscription_service_ids')
    elements.excludedServiceIds = document.querySelector('#subscription_excluded_service_ids')
    elements.addExcludedService = document.querySelector('#add_excluded_services_to_subscription')
    elements.promotionType = document.querySelector('#subscription_promotion_type')
    elements.servicesType = document.querySelector('#subscription_services_type')
    elements.generalDiscount = document.querySelector('#subscription_general_discount')
    elements.enableDiscountFields = document.querySelector('#enable_general_discount_fields')
    elements.serviceTypeMulti = document.querySelector('#subscription_promotion_service_type_ids')
    elements.categoryIdsMulti = document.querySelector('#subscription_promotion_category_ids')
    elements.discountMulti = document.querySelector('#subscription_promotion_included_discount')
    elements.startDate = document.querySelector('#subscription_promotion_start_date')
    elements.endDate = document.querySelector('#subscription_promotion_end_date')
    elements.dayTimeSelector = document.querySelector('#subscription_promotion_day_time_selector')
    elements.excludedServicesSearch = document.querySelector('#excluded_services_search')
    elements.includedServicesSearch = document.querySelector('#included_services_search')
    elements.subscriptionForm = document.querySelector('#subscritpion_promotion_form')
    elements.customList = document.querySelector('#subscription_custom_list')
    elements.serviceTypeFilter = document.querySelector('#subscription_service_type_filter')
    elements.categoryFilter = document.querySelector('#subscription_category_filter')
    elements.promotionRuleFilter = document.querySelector('#subscription_promotion_rule_filter')
    elements.subscriptionServiceTypes = document.querySelector('#subscription_service_types')
    elements.subscriptionCategories = document.querySelector('#subscription_categories')
    elements.promotionRuleFields = document.querySelector('#promotion_rule_base_fields')
    elements.submitButton = document.querySelector('#subscription_promotion_submit_btn')
    elements.cashbackDiscount = document.querySelector('#subscription_promotion_cashback_discount')
    elements.enableTopListing = document.querySelector('#subscription_promotion_enable_top_listing')
    elements.enableM4bPortal = document.querySelector('#subscription_promotion_enable_m4b_portal')
    elements.indexListingTable = document.querySelector('#subscription_promotion_listing')
    elements.showListingTable = document.querySelector('#subscription_promotion_services')
    elements.excludedTable = document.querySelector('#subscription_promotion_excluded_services')
    elements.serviceTypesTable = document.querySelector('#subscription_promotion_service_types')
    elements.categoriesTable = document.querySelector('#subscription_promotion_categories')
  }

  const initializeModalSelectors = () => {
    elements.associatedStoreIds = document.querySelector('#linked_service_ids')
    elements.saveExcludedServicesButton = document.querySelector('#save_exclude_service_btn')
    elements.saveCustomServicesButton = document.querySelector('#save_custom_services_btn')
    $(elements.saveExcludedServicesButton).on('click', saveExcludedServices)
    $(elements.saveCustomServicesButton).on('click', saveCustomServices)
    elements.storesTable = document.querySelector('#services')
    elements.associatedStoresTable = document.querySelector('#linked_services')
    elements.excludedStoresTable = document.querySelector('#excluded_services')
    elements.associatedExcludedStoresTable = document.querySelector('#linked_excluded_services')
  }

  const initializeListeners = () => {
    $(elements.servicesType).on('change', toggleFieldsOnServicesType)
    $(elements.generalDiscount).on('change', toggleFieldsOnGeneralDiscount)
    $(elements.addService).on('click', openServiceModal)
    $(elements.addExcludedService).on('click', openExcludedServiceModal)
    $(elements.dayTimeSelector).on('click', toggleShifts)
    $(elements.serviceTypeFilter).on('click', toggleServiceTypes)
    $(elements.categoryFilter).on('click', toggleCategories)
    $(elements.submitButton).on('click', validateFields)
    $(elements.promotionRuleFilter).on('click', toggleGeneralDiscount)
    $(elements.excludedServicesSearch).on('click', refreshExcludedModalFeilds)
    $(elements.includedServicesSearch).on('click', refreshIncludedModalFeilds)
    $(elements.startDate).on('dp.show', openStartDatePicker)
    $(elements.startDate).on('dp.change', validateDuration)
    $(elements.endDate).on('dp.show', openEndDatePicker)
    $(document).on('click', '.add_service_to_subscription', associateStores)
    $(document).on('click', '.add_exclude_service_to_subscription', associateExcludeStores)
    $(document).on('click', '.remove_from_subscription', removeAssociatedStores)
    $(document).on('click', '.remove_exclude_service_from_subscription', removeExcludeStores)
  }

  const openServiceModal = (event) => {
    event.target.disabled = true
    event.target.innerHTML = 'Please wait...'

    $.ajax({
      type: 'POST',
      dataType: 'script',
      url: '/user_subscriptions/subscription_promotions/associate_custom_services',
      data: { linked_service_ids: elements.associatedServiceIds.value },
      success: () => {
        initializeModalSelectors()
        initializeDataTable(elements.storesTable)
        initializeDataTable(elements.associatedStoresTable)
      }
    })
  }

  const initializeDataTable = (element) => {
    $(element).DataTable({
      'paging': true,
      'lengthMenu': [[10, 25, 50, 200], [10, 25, 50, 200]],
      'lengthChange': true,
      'searching': false,
      'ordering': true,
      'order': [],
      'info': true,
      'autoWidth': false
    })
  }

  const openExcludedServiceModal = (event) => {
    event.target.disabled = true
    event.target.innerHTML = 'Please wait...'

    $.ajax({
      type: 'POST',
      dataType: 'script',
      url: '/user_subscriptions/subscription_promotions/associate_excluded_services',
      data: { linked_service_ids: elements.excludedServiceIds.value },
      success: () => {
        initializeModalSelectors()
        initializeDataTable(elements.excludedStoresTable)
        initializeDataTable(elements.associatedExcludedStoresTable)
      }
    })
  }

  const saveExcludedServices = () => {
    const btn = elements.saveExcludedServicesButton
    btn.disabled = true
    btn.innerHTML = 'Please wait...'
    const id = elements.subscriptionPromotionId

    $.ajax({
      type: 'POST',
      dataType: 'script',
      url: `/user_subscriptions/subscription_promotions/${id}/update_excluded_services`,
      data: {
        excluded_shop_ppd_detail_ids: elements.excludedServiceIds.value
      },
      success: () => {
        $('#associate_excluded_services').modal('hide')
        window.location.href = `/user_subscriptions/subscription_promotions/${id}/`
      },
      error: () => {
        btn.disabled = false
        btn.innerHTML = 'Save & update shops'
        if (id == null || id === '') {
          alert('Save & update is not functioning correctly for new promotions.' +
            ' Please click Close and then Save to proceed.')
        } else {
          alert('something went wrong, please try again later')
        }
      }
    })
  }

  const saveCustomServices = () => {
    const btn = elements.saveCustomServicesButton
    btn.disabled = true
    btn.innerHTML = 'Please wait...'
    const id = elements.subscriptionPromotionId

    $.ajax({
      type: 'POST',
      dataType: 'script',
      url: `/user_subscriptions/subscription_promotions/${id}/update_custom_services`,
      data: {
        custom_shop_ppd_detail_ids: elements.associatedServiceIds.value,
        id: elements.subscriptionPromotionId
      },
      success: () => {
        $('#associate_services').modal('hide')
        window.location.href = `/user_subscriptions/subscription_promotions/${id}/`
      },
      error: () => {
        btn.disabled = false
        btn.innerHTML = 'Save & update shops'
        if (id == null || id === '') {
          alert('Save & update is not functioning correctly for new promotions.' +
            ' Please click Close and then Save to proceed.')
        } else {
          alert('something went wrong, please try again later')
        }
      }
    })
  }

  const openStartDatePicker = () => {
    $(elements.startDate).data('DateTimePicker').minDate(new Date())
  }

  const openEndDatePicker = () => {
    $(elements.endDate).data('DateTimePicker').minDate($(elements.startDate).val())
  }

  const validateDuration = () => {
    const startDate = $(elements.startDate).val()
    const endDate = $(elements.endDate).val()

    if (startDate > endDate) {
      $(elements.endDate).data('DateTimePicker').date(startDate)
    }
  }

  const toggleShifts = () => {
    const isChecked = elements.dayTimeSelector?.checked
    $('#day_time_selector_part').attr('hidden', !isChecked)

    if (!isChecked) {
      $('.form-fields .shift_destroy').val('1')
    } else {
      $('.form-fields .shift_destroy').val('0')
    }
  }

  const toggleServiceTypes = () => {
    if (elements.serviceTypeFilter?.checked) {
      $(elements.subscriptionServiceTypes).removeAttr('hidden')
    } else {
      $(elements.subscriptionServiceTypes).attr('hidden', true)
      $(elements.serviceTypeMulti).val(null).multiselect('refresh')
    }
  }

  const toggleCategories = () => {
    if (elements.categoryFilter?.checked) {
      $(elements.subscriptionCategories).removeAttr('hidden')
    } else {
      $(elements.subscriptionCategories).attr('hidden', true)
      $(elements.categoryIdsMulti).val(null).multiselect('refresh')
    }
  }

  const validateFields = (event) => {
    if (elements.serviceTypeFilter?.checked && $(elements.serviceTypeMulti).val() === null) {
      event.preventDefault()
      alert('Please select Service Types')
    } else if (elements.categoryFilter?.checked && $(elements.categoryIdsMulti).val() === null) {
      event.preventDefault()
      alert('Please select Categories')
    } else if (elements.promotionRuleFilter?.checked &&
      $(elements.generalDiscount).val() === 'true' &&
      $(elements.discountMulti).val() === null) {
      event.preventDefault()
      alert('Please select General discounts')
    }
  }

  const toggleGeneralDiscount = () => {
    if (elements.promotionRuleFilter?.checked) {
      $(elements.promotionRuleFields).removeAttr('hidden')
    } else {
      $(elements.promotionRuleFields).attr('hidden', true)
      $(elements.enableDiscountFields).attr('hidden', true)
      $(elements.generalDiscount).val('false')
      $(elements.discountMulti).val([])
      $(elements.cashbackDiscount).val('false')
      $(elements.enableTopListing).val('false')
      $(elements.enableM4bPortal).val('false')
    }
  }

  const refreshExcludedModalFeilds = () => {
    $('#shop_name').val('')
    $('#service_type').val('All')
    $('form#search-excluded-services-form').submit()
  }

  const refreshIncludedModalFeilds = () => {
    $('#shop_name').val('')
    $('#service_type').val('All')
    $('form#search-included-services-form').submit()
  }

  const toggleFieldsOnServicesType = () => {
    if ($(elements.servicesType).val() === 'custom') {
      $(elements.customList).removeAttr('hidden')
    } else {
      $(elements.customList).attr('hidden', true)
    }
  }

  const toggleFieldsOnGeneralDiscount = () => {
    if ($(elements.generalDiscount).val() === 'true') {
      $(elements.enableDiscountFields).removeAttr('hidden')
    } else {
      $(elements.enableDiscountFields).attr('hidden', true)
    }
  }

  const removeAssociatedStores = (event) => {
    event.stopImmediatePropagation()
    const selectedElement = event.target

    $(elements.associatedStoresTable)
      .DataTable()
      .row(selectedElement.closest('tr'))
      .remove()
      .draw()

    $(elements.storesTable)
      .DataTable()
      .row
      .add([
        selectedElement.dataset.id,
        selectedElement.dataset.name,
        selectedElement.dataset.arName,
        `<a href='javascript:;' class='add_service_to_subscription'
        data-id=${+selectedElement.dataset.id}
        data-name=${selectedElement.dataset.name}
        data-ar-Name=${selectedElement.dataset.arName}>Add</a>`
      ])
      .draw()

    let existingStoreIds = elements.associatedServiceIds.value

    if (existingStoreIds === '') {
      existingStoreIds = []
    } else {
      existingStoreIds = JSON.parse(existingStoreIds)
    }

    const itemIndex = existingStoreIds.indexOf(+selectedElement.dataset.id)

    if (itemIndex !== -1) {
      existingStoreIds.splice(itemIndex, 1)
    }

    if (existingStoreIds.length) {
      existingStoreIds = JSON.stringify(existingStoreIds)
    } else {
      existingStoreIds = ''
    }

    elements.associatedServiceIds.value = existingStoreIds
    elements.associatedStoreIds.value = existingStoreIds
  }

  const removeExcludeStores = (event) => {
    event.stopImmediatePropagation()
    const selectedElement = event.target

    $(elements.associatedExcludedStoresTable)
      .DataTable()
      .row(selectedElement.closest('tr'))
      .remove()
      .draw()

    $(elements.excludedStoresTable)
      .DataTable()
      .row
      .add([
        selectedElement.dataset.id,
        selectedElement.dataset.name,
        selectedElement.dataset.arName,
        `<a href='javascript:;' class='add_exclude_service_to_subscription'
        data-id=${selectedElement.dataset.id}
        data-name=${selectedElement.dataset.name}
        data-ar-Name=${selectedElement.dataset.arName}>Add</a>`
      ])
      .draw()

    let existingStoreIds = elements.excludedServiceIds.value

    if (existingStoreIds === '') {
      existingStoreIds = []
    } else {
      existingStoreIds = JSON.parse(existingStoreIds)
    }

    const itemIndex = existingStoreIds.indexOf(+selectedElement.dataset.id)

    if (itemIndex !== -1) {
      existingStoreIds.splice(itemIndex, 1)
    }

    if (existingStoreIds.length) {
      existingStoreIds = JSON.stringify(existingStoreIds)
    } else {
      existingStoreIds = ''
    }

    elements.excludedServiceIds.value = existingStoreIds
    elements.associatedStoreIds.value = existingStoreIds
  }

  const associateStores = (event) => {
    const selectedElement = event.target
    let existingStoreIds = elements.associatedServiceIds.value
    if (existingStoreIds === '') {
      existingStoreIds = []
    } else {
      existingStoreIds = JSON.parse(existingStoreIds)
    }
    const itemIndex = existingStoreIds.indexOf(selectedElement.dataset.id)

    $(elements.storesTable)
      .DataTable()
      .row(selectedElement.closest('tr'))
      .remove()
      .draw()

    if (itemIndex > -1) {
      return
    }

    $(elements.associatedStoresTable)
      .DataTable()
      .row
      .add([
        selectedElement.dataset.id,
        selectedElement.dataset.name,
        selectedElement.dataset.arName,
        `<a href='javascript:;' class='remove_from_subscription'
          data-id=${selectedElement.dataset.id}
          data-name=${selectedElement.dataset.name}
          data-ar-Name=${selectedElement.dataset.arName}>
          Remove
         </a>`
      ])
      .draw()

    existingStoreIds.push(+selectedElement.dataset.id)

    if (existingStoreIds.length) {
      existingStoreIds = JSON.stringify(existingStoreIds)
    } else {
      existingStoreIds = ''
    }

    elements.associatedServiceIds.value = existingStoreIds
    elements.associatedStoreIds.value = existingStoreIds
  }

  const associateExcludeStores = (event) => {
    const selectedElement = event.target
    let existingStoreIds = elements.excludedServiceIds.value
    if (existingStoreIds === '') {
      existingStoreIds = []
    } else {
      existingStoreIds = JSON.parse(existingStoreIds)
    }
    const itemIndex = existingStoreIds.indexOf(selectedElement.dataset.id)

    $(elements.excludedStoresTable)
      .DataTable()
      .row(selectedElement.closest('tr'))
      .remove()
      .draw()

    if (itemIndex > -1) {
      return
    }

    $(elements.associatedExcludedStoresTable)
      .DataTable()
      .row
      .add([
        selectedElement.dataset.id,
        selectedElement.dataset.name,
        selectedElement.dataset.arName,
        `<a href='javascript:;' class='remove_exclude_service_from_subscription'
          data-id=${selectedElement.dataset.id}
          data-name=${selectedElement.dataset.name}
          data-ar-Name=${selectedElement.dataset.arName}>
          Remove
         </a>`
      ])
      .draw()

    existingStoreIds.push(+selectedElement.dataset.id)

    if (existingStoreIds.length) {
      existingStoreIds = JSON.stringify(existingStoreIds)
    } else {
      existingStoreIds = ''
    }

    elements.excludedServiceIds.value = existingStoreIds
    elements.associatedStoreIds.value = existingStoreIds
  }

  const initializeMultiselect = (elementId) => {
    $(elementId).multiselect({
      enableFiltering: true,
      enableCaseInsensitiveFiltering: true,
      maxHeight: 250,
      buttonWidth: '100%'
    })
  }

  const intializeDateTimePicker = () => {
    initializeMultiselect(elements.serviceTypeMulti)
    initializeMultiselect(elements.categoryIdsMulti)
    initializeMultiselect(elements.discountMulti)
    initializeDataTable(elements.indexListingTable)
    initializeDataTable(elements.showListingTable)
    initializeDataTable(elements.excludedTable)
    initializeDataTable(elements.serviceTypesTable)
    initializeDataTable(elements.categoriesTable)

    let startDate = elements.startDate?.value?.split(' ')[0]
    const endDate = elements.endDate?.value?.split(' ')[0]
    const date = new Date()
    const day = date.getDate()
    const year = date.getFullYear()
    const monthName = date.toLocaleString('default', { month: 'long' })
    const controllerAction = elements.subscriptionForm?.getAttribute('data-controller-action')

    if (moment() > moment(startDate)) {
      startDate = moment(startDate)
    } else {
      startDate = moment().millisecond(0).second(0).minute(0).hour(0)
    }

    $(elements.startDate).datetimepicker({
      format: 'YYYY-MM-DD',
      minDate: startDate,
      defaultDate: new Date()
    })

    $(elements.endDate).datetimepicker({
      format: 'YYYY-MM-DD',
      useCurrent: false,
      minDate: startDate,
      defaultDate: new Date(`${monthName} ${day}, ${year}`)
    })

    if (controllerAction === 'edit' || controllerAction === 'update') {
      const minDate = moment(startDate)
      const maxDate = moment(endDate)

      $(elements.endDate).data('DateTimePicker').minDate(minDate)
      $(elements.startDate).data('DateTimePicker').maxDate(maxDate)
    }

    (function () {
      const script = document.createElement('script')
      script.src = 'https://cdn.jsdelivr.net/npm/flatpickr'
      document.head.appendChild(script)
    })()
  }

  if (window.location.pathname.includes('subscription_promotions')) {
    initializeSelectors()
    intializeDateTimePicker()
    initializeListeners()
    toggleShifts()
    toggleFieldsOnServicesType()
    toggleServiceTypes()
    toggleCategories()
    toggleGeneralDiscount()
    toggleFieldsOnGeneralDiscount()
  }
}

export default SubscriptionPromotion
